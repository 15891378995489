<template>
  <div>
    <div class="mb-4">
      <trac-back-button class="mb-2">Back</trac-back-button>
      <h4 class="font-medium">Sales Details</h4>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div
        class="white-shadow bg-white rounded-lg p-4 col-span-4 flex items-center gap-3"
      >
        <div>
          <img src="@/assets/svg/receipt-icon-rounded.svg" />
        </div>
        <div>
          <h5 class="text-lightGray-200 text-sm">Receipt Number</h5>
          <p class="font-medium text-sm">{{ sale.receipt_number || "N/A" }}</p>
        </div>
      </div>
      <div
        class="white-shadow bg-white rounded-lg p-4 col-span-4 flex items-center gap-3"
      >
        <div><img src="@/assets/svg/receipt-icon-rounded.svg" /></div>
        <div>
          <h5 class="text-lightGray-200 text-sm">Transaction Type</h5>
          <p class="font-medium text-sm">{{ sale.sales_type }}</p>
        </div>
      </div>
      <div
        class="white-shadow bg-white rounded-lg p-4 col-span-4 flex items-center gap-3"
      >
        <div><img src="@/assets/svg/pay-method-rounded.svg" /></div>
        <div>
          <h5 class="text-lightGray-200 text-sm">Payment Method</h5>
          <p class="font-medium text-sm capitalize">
            {{
              sale.payment_method
                ? sale.payment_method.replace("_", " ")
                : "N/A"
            }}
          </p>
        </div>
      </div>
      <div
        class="white-shadow bg-white rounded-lg p-4 col-span-4 flex items-center gap-3"
      >
        <div><img src="@/assets/svg/amount-rounded.svg" /></div>
        <div>
          <h5 class="text-lightGray-200 text-sm">Amount Received</h5>
          <p class="font-medium text-sm">
            {{ sale.total_price | formatPrice }}
          </p>
        </div>
      </div>
      <div
        class="white-shadow bg-white rounded-lg p-4 col-span-4 flex items-center gap-3"
      >
        <div><img src="@/assets/svg/calendar-rounded.svg" /></div>
        <div>
          <h5 class="text-lightGray-200 text-sm">Payment Date</h5>
          <p class="font-medium text-sm">
            {{ moment(sale.actual_sale_time).format("do-MMM, yyyy") }} -
            <span class="text-lightGray-200">
              {{ moment(sale.actual_sale_time).format("hh:mm A") }}
            </span>
          </p>
        </div>
      </div>
      <div
        class="white-shadow bg-white rounded-lg p-4 col-span-4 flex items-center gap-3"
      >
        <div><img src="@/assets/svg/amount-rounded.svg" /></div>
        <div>
          <h5 class="text-lightGray-200 text-sm">Sales Type</h5>
          <p class="font-medium text-sm capitalize">{{ sale.sale_mode }}</p>
        </div>
      </div>

      <div class="white-shadow bg-white rounded-lg p-4 col-span-6">
        <h5
          class="text-accentDark pb-4 mb-4 border-b border-b-lightGray-100 font-bold text-md"
        >
          ORDER DETAILS
        </h5>

        <div
          v-for="item in sale.items"
          :key="item._id"
          class="flex justify-between mb-4"
        >
          <div class="flex gap-3 items-center">
            <div><img src="@/assets/svg/placeholder.svg" /></div>
            <div>
              <p class="text-sm font-medium">{{ item.name }}</p>
            </div>
          </div>
          <p class="text-sm font-medium">
            {{ item.unit_price | formatPrice }} x {{ item.quantity }}
          </p>
        </div>

        <div class="flex justify-end">
          <div class="flex items-end flex-col">
            <div class="flex gap-6 items-center justify-between w-full">
              <h6 class="text-xs">Subtotal:-</h6>
              <p class="text-sm font-medium">
                {{ totalCost | formatPrice }}
              </p>
            </div>
            <div class="flex gap-6 items-center justify-between w-full">
              <h6 class="text-xs">Discount:-</h6>
              <p class="text-sm font-medium">
                {{ totalDiscount | formatPrice }}
              </p>
            </div>
            <div
              class="flex gap-6 items-center text-lightBlue justify-between w-full"
            >
              <h6 class="text-lg font-medium">Total:-</h6>
              <p class="text-sm font-medium">
                {{ total | formatPrice }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="white-shadow bg-white rounded-lg p-4 col-span-6">
        <h5
          class="text-accentDark pb-4 mb-4 border-b border-b-lightGray-100 font-bold text-md"
        >
          STORE INFORMATION
        </h5>

        <div class="grid grid-cols-12 flex-col gap-4">
          <div class="col-span-full grid grid-cols-12">
            <h6 class="text-primaryGray text-xs col-span-3">Sold By:</h6>
            <p class="text-primaryBlue col-span-3 capitalize">
              {{ sale.sale_agent.name }}
            </p>
          </div>
          <div class="col-span-full grid grid-cols-12">
            <h6 class="text-primaryGray text-xs col-span-3">Store Name:</h6>
            <p
              class="text-primaryBlue underline cursor-pointer col-span-3 capitalize"
              @click="goToStore"
            >
              {{ sale.store.name }}
            </p>
          </div>
        </div>
      </div>

      <div class="white-shadow bg-white rounded-lg p-4 col-span-6">
        <h5
          class="text-accentDark pb-4 mb-4 border-b border-b-lightGray-100 font-bold text-md"
        >
          CUSTOMER DETAILS
        </h5>

        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-6 text-sm">
            <h6 class="text-primaryGray font-medium">Customer Name:</h6>
            <p class="text-primaryBlue capitalize" @click="goToCustomer">
              {{ sale.customer_details.name || "N/A" }}
            </p>
          </div>
          <div class="col-span-6 text-sm">
            <h6 class="text-primaryGray font-medium">Customer ID:</h6>
            <p class="font-medium capitalize">
              {{
                !sale.customer_details.name ? "N/A" : sale.customer_details.id
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="white-shadow bg-white rounded-lg p-4 col-span-6">
        <h5
          class="text-accentDark pb-4 mb-4 border-b border-b-lightGray-100 font-bold text-md"
        >
          PAYMENT LOG
        </h5>

        <template v-for="(payment, index) in sale.payment_records">
          <div
            class="grid grid-cols-12 gap-4"
            :class="{ 'mb-3': index + 1 !== sale.payment_records.length }"
            :key="index"
          >
            <div class="col-span-6 text-sm">
              <h6 class="text-primaryGray font-medium">Payment Method:</h6>
              <p class="font-medium cursor-pointer capitalize">
                {{
                  payment.payment_method
                    ? payment.payment_method.replace("_", " ")
                    : "N/A"
                }}
              </p>
            </div>
            <div class="col-span-6 text-sm">
              <h6 class="text-primaryGray font-medium">Payment Reference:</h6>
              <p class="font-medium cursor-pointer capitalize">
                {{ payment.payment_reference || "N/A" }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- <trac-dashboard-table :tableHeaders="tableHeaders" :data="sale.items">
      <template #default="{ row: item, index }">
        <trac-dashboard-table-data>
          <template>{{ index + 1 }}</template>
        </trac-dashboard-table-data>
        <trac-dashboard-table-data>
          <template>
            <span class="font-bold">{{ item.name }}</span>
          </template>
        </trac-dashboard-table-data>
        <trac-dashboard-table-data>
          x {{ item.quantity }}
        </trac-dashboard-table-data>
        <trac-dashboard-table-data>
          {{ item.unit_price | formatPrice }}
        </trac-dashboard-table-data>
        <trac-dashboard-table-data>
          <span
            v-if="['value', 'percentage', 'none'].includes(item.discount_type)"
          >
            {{ getDiscount(item) | formatPrice }}
          </span>
          <span v-else>N/A</span>
        </trac-dashboard-table-data>
        <trac-dashboard-table-data>
          {{ (item.unit_price * item.quantity) | formatPrice }}
        </trac-dashboard-table-data>
      </template>
    </trac-dashboard-table> -->

    <!-- <div class="flex justify-end mt-8 text-md">
      <div class="flex flex-col gap-2">
        <div class="flex justify-between gap-3">
          <p>Cost Total:</p>
          &nbsp;
          <span class="font-bold">
            {{ totalCost | formatPrice }}
          </span>
        </div>
        <div class="flex justify-between gap-3">
          <p>Total Discount:</p>
          &nbsp;
          <span class="font-bold">
            {{ totalDiscount | formatPrice }}
          </span>
        </div>
        <div class="flex justify-between gap-3">
          <p>Total:</p>
          &nbsp;
          <span class="font-bold">
            {{ total | formatPrice }}
          </span>
        </div>
      </div>
    </div> -->

    <div class="flex flex-row mt-5 justify-end gap-5 reissue-receipt-hold">
      <!-- @button-clicked="resendReceiptModal = true" -->
      <trac-button @button-clicked="reprintReceipt" class="uppercase">
        Reissue receipt
      </trac-button>
      <!-- <trac-button
        @button-clicked="selectCustomerModal = true"
        class="uppercase"
        >Select customer</trac-button
      > -->
    </div>
  </div>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { getDiscountValue } from "../../utils";
import moment from "moment";

export default {
  name: "SaleInfo",
  data() {
    return {
      sale: {},
      moment,
      tableHeaders: [
        { name: "", classValue: "bg-white" },
        { name: "ITEM", classValue: "bg-white" },
        { name: "QUANTITY", classValue: "bg-white" },
        { name: "COST", classValue: "bg-white" },
        { name: "DISCOUNT", classValue: "bg-white" },
        { name: "TOTAL", classValue: "bg-white" },
      ],
    };
  },
  computed: {
    totalCost() {
      return this.sale?.items.reduce(
        (acc, item) => acc + item.quantity * item.unit_price,
        0
      );
    },
    totalDiscount() {
      return this.sale.items.reduce(
        (acc, item) => acc + this.getDiscount(item, "unit_price"),
        0
      );
    },
    total() {
      return this.totalCost - this.totalDiscount;
    },
  },
  created() {
    this.sale = GET_LOCAL_DB_DATA("sale-record");
  },
  methods: {
    getDiscount(item) {
      return getDiscountValue(item);
    },
    goToStore() {
      SAVE_LOCAL_DB_DATA("store_details", this.sale.store);
      this.$router.push({
        name: "AllStores",
      });
    },
    goToCustomer() {
      SAVE_LOCAL_DB_DATA("customer_details", {
        ...this.sale.customer_details,
        _id: this.sale.customer_details.id,
      });
      this.$router.push({
        name: "Customers",
      });
    },
    reprintReceipt() {
      setTimeout(() => {
        print();
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped></style>
